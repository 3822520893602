html, body, #root {
  overflow-x: hidden;
  /* min-width: 780px; */

}

.card{
  
  cursor:pointer;
  transition:transform 200ms ease-in;
  
}

.card:hover{
  transform: scale(1.02);
  box-shadow:0 .25rem .75rem rgba(0, 0, 0, .05);
}

.card-button {
  background-color: #fff;
  border: 1.15px solid #EB0A1E;
  border-radius: 25px; /* Add rounded corners for a pill shape */
  color: #EB0A1E;
  font-size: 14px; /* Increase font size for better visibility */
  padding: 2px 16px; /* Increase padding for a larger button */
  font-weight: bold;
  line-height: 1.4; /* Add line-height to center text vertically */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}



.card:hover .card-button {
  background-color: #EB0A1E;
  color: #fff;
}

.welcome-button {
  background-color: #EB0A1E;
  border: 1.15px solid #fff; 
  border-radius: 25px; /* Add rounded corners for a pill shape */
  color: #fff;
  font-size: 14px; /* Increase font size for better visibility */
  padding: 8px 16px; /* Increase padding for a larger button */
  font-weight: bold;
  line-height: 1.4; /* Add line-height to center text vertically */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.welcome-button:hover {
  background-color: #fff;
  color: #EB0A1E;
}

.cat-button {
  /* background-color: #0000;
  border: 1.15px solid #fff;  */
  border-radius: 25px; /* Add rounded corners for a pill shape */
  /* color: #fff; */
  font-size: 14px; /* Increase font size for better visibility */
  padding: 8px 16px; /* Increase padding for a larger button */
  margin-left: 12px;


  font-weight: bold;
  line-height: 1.4; /* Add line-height to center text vertically */
  /* transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; */
}



:root {
  --jumbotron-padding-y: 1rem;
}

.full-screen {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.content-wrapper {
  text-align: center;
  width: 100%;
}

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
}

@media (min-width: 800px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-size: 3rem;
  font-weight: bold; 
}

.lead {
  font-size: 1.5rem;
}

.jumbotron .container {
  max-width: 40rem;
}

/* .category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.category {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px;
  margin: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.category:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.category-icon {
  margin-right: 10px;
  font-size: 24px;
  color: #333;
}

.category-name {
  font-size: 16px;
  color: #333;
} */
.category {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid transparent;
  /* border-radius: 4px; */
  margin-bottom: 10px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.category-icon {
  margin-left: 10px;
}

.category-name {
  flex-grow: 1;
  padding-left: 10px; /* Add left padding to the category-name */

}

.category:hover {
  background-color: rgba(235, 10, 30, 0.1);
}

.category.selected {
  border-color: #EB0A1E;
  background-color: #EB0A1E;
  color: #ffffff;
}


.image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed gray;
  padding: 2em;
  border-radius: 5px;
  cursor: pointer;
  min-height: 150px;
  min-width: 300px;
}

.image-preview {
  max-width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 1em;
}

.pretty-navbar{
background: #FFFFFF;
border-bottom: 1px solid #dee2e6;
margin-top: 0;
  padding-top: 0;
}

.sidebar {
  /* position: sticky; */
  margin-top: 0;
  padding-top: 0;
  padding: 0;

  border-right: 1px solid #dee2e6; /* Change the color and thickness to your preference */

  
}

.custom-sidebar {
  /* padding: 0 1rem; */
  padding-top: 0.5rem; /* Adjust this value to make the sidebar flush with the top navbar */
}

.search-input-container {
  position: relative;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-control {
  padding-right: 30px; /* Adjust this value according to the size of the icon */
}
